/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("@rails/ujs").start()
require("turbolinks").start()

global.$ = require("jquery")
require('jquery-ui');

require("@claviska/jquery-minicolors")


global.moment = require("moment")
require("moment-timezone")
require('chartjs-adapter-moment');
global.ChartDataLabels = require('chartjs-plugin-datalabels');
global.Chart = require("chart.js/auto")

require("@rails/activestorage").start()
require("bootstrap")
require("bootstrap-select")
require("bootstrap-slider")
require("tempusdominus")
require("tempusdominus-bootstrap")
require("@fortawesome/fontawesome-pro/js/all.js")
require("packs/devices")

import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);


/*
Chart.Chart.register({
  beforeDraw: function(chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'sans-serif';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      // Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 12;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.bottom));
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;


      var lines = txt.split('|');

      if (lines.length == 1) {
        ctx.fillText(txt, centerX, centerY);
        ctx.fillText(txt, centerX, centerY);
        return;
      }
      var line = '';
      var lines = txt.split('|');

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  }
});
*/

$(document).on('ready turbolinks:load', function() {
    $(window).trigger('load.bs.select.data-api');
});

$(document).on('turbolinks:before-cache', function() {
  const $picker = $('.selectpicker');
  const picker = $picker.data('selectpicker');
  if (picker) {
    picker.destroy();
    $picker.addClass('selectpicker');
  }
});



$(document).ready(function(){
  var adapters = global.Chart._adapters;

  var FORMATS = {
    datetime: 'h:mm A - MM/D/YYYY',
    millisecond: 'h:mm:ss.SSS a',
    second: 'h:mm:ss a',
    minute: 'h:mm a',
    hour: 'hA',
    day: 'MMM D',
    week: 'll',
    month: 'MMM YYYY',
    quarter: '[Q]Q - YYYY',
    year: 'YYYY'
  };

  adapters._date.override(typeof moment === 'function' ? {
    _id: 'moment', // DEBUG ONLY

    formats: function() {
      return FORMATS;
    },

    parse: function(value, format) {
      if (typeof value === 'string' && typeof format === 'string') {
        value = moment(value, format);
      } else if (!(value instanceof moment)) {
        value = moment(value, moment.ISO_8601);
      }
      return value.isValid() ? value.valueOf() : null;
    },

    format: function(time, format) {
//      console.log("format called on "+time+ " format "+format);
      return moment(time).tz(moment().tz()).format(format);
    },

    add: function(time, amount, unit) {
      return moment(time).add(amount, unit).valueOf();
    },

    diff: function(max, min, unit) {
      return moment.duration(moment(max).diff(moment(min))).as(unit);
    },

    startOf: function(time, unit, weekday) {
      time = moment(time);
      if (unit === 'isoWeek') {
              return time.isoWeekday(weekday).valueOf();
      }
      return time.startOf(unit).valueOf();
    },

    endOf: function(time, unit) {
      return moment(time).endOf(unit).valueOf();
    },

    // DEPRECATIONS

    /**
     * Provided for backward compatibility with scale.getValueForPixel().
     * @deprecated since version 2.8.0
     * @todo remove at version 3
     * @private
     */
    _create: function(time) {
      return moment(time);
    },
  } : {});

});
