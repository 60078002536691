data_reload = function(id, time_range, from_date, to_date){
  if (time_range > 0){
    var url = "/devices/"+id+"/dashboard.json?device[time_range]="+time_range;
  } else {
    var url = "/devices/"+id+"/dashboard.json?device[from_date]="+from_date+"&device[to_date]="+to_date;
  }

  $.getJSON(url, null, function(json) {
    if (json.upd){
      let d = $('#last-data-'+id)[0];
      if (d != undefined){
        if (d.dataset.update != json.upd){
          console.log("new update for device "+id+": "+ json.upd);
        }
        d.dataset.update = json.upd;
      } else {
        console.log("Warning: can't find last data element for "+id);
      }
    }
    if (json.runtimes){
      json.runtimes.forEach(function(data, key){
        if (data != null){
          var bar = $("#runtime-"+id+"-"+key);
          if (bar != undefined && bar[0] != undefined){
            bar[0].setAttribute("value", data.percent);
          }
          var value = $("#runtime-"+id+"-"+key+"-value");
          if (value != undefined && value[0] != undefined){
            value[0].innerHTML=data.value;
          }
          var percent = $("#runtime-"+id+"-"+key+"-percent");
          if (percent != undefined && percent[0] != undefined){
            percent[0].innerHTML=data.percent;
          }
        }
      });
    }
    if (json.svg) {
      let svg = $('#device-'+id+'-svg')[0];
      if (svg != undefined){
        svg.innerHTML = json.svg;
      }
    }
    if (json.temp){
      json.temp.forEach(function(data, key){
        if (data != null){
          let s = $('#temp-'+id+'-cur-'+key)[0];
          if (s != undefined){
            s.dataset.cur = data["cur"];
            s.dataset.fmtCur = data["fmt_cur"];
            s.dataset.fmtAvg = data["fmt_avg"];
          }
          let g = $("#graph-"+id+"-"+key)[0];
          if (g != undefined){
            g.dataset.labels = data["labels"];
            g.dataset.points = data["points"];
          }
        }
      });
    }
    if (json.pressure){
      json.pressure.forEach(function(data, key){
        if (data != null){
          let s = $('#pressure-'+id+'cur-'+key)[0];
          if (s != undefined){
            s.dataset.cur = data["cur"];
            s.dataset.fmtCur = data["fmt_cur"];
            s.dataset.fmtAvg = data["fmt_avg"];
          }
          let g = $('#pressure-graph-'+id+'-data-'+key)[0];
          if (g != undefined){
            g.dataset.labels = data["labels"];
            g.dataset.points = data["points"];
          }
        }
      });
    }
    if (json.bar_temp){
      data = json.bar_temp;
      let g = $('#bar-data-'+id+'-temp')[0];
      if (g != undefined){
        g.dataset.labels = data["labels"];
        g.dataset.data = data["points"];
      }
    }
    if (json.bar_runtime){
      data = json.bar_runtime;
      let g = $('#bar-data-'+id+'-runtime')[0];
      if (g != undefined){
        g.dataset.labels = data["labels"];
        g.dataset.data = data["points"];
      }
    }
    if (json.bar_pressure){
      data = json.bar_pressure;
      let g = $('#bar-data-'+id+'-pressure')[0];
      if (g != undefined){
        g.dataset.labels = data["labels"];
        g.dataset.data = data["points"];
      }
    }


  });
};

bar_timeout = function(id, chart, mode){
    setTimeout(function() {
      var data_dom = $('#bar-data-'+id+'-'+mode)[0];
      if (data_dom == undefined){
        return
      }
      var data = data_dom.dataset;
      if (data.data != undefined && data.labels != undefined){
        chart.data.datasets[0].data = data.data.split(",");
        chart.data.labels = data.labels.split(",");
        chart.update();
      }


      bar_timeout(id, chart, mode);
    }, 5000);
};


graph_timeout = function(id, graph_chart, i){
    setTimeout(function() {
      var graph_dom = $('#graph-'+id+'-'+i)[0];
      var temp_dom = $('#temp-'+id+'-cur-'+i)[0];

      if (graph_dom == undefined || temp_dom == undefined){
        return
      }
      // graph widget
      var data = graph_dom.dataset;
      graph_chart.data.datasets[0].data = JSON.parse(data.points);
      graph_chart.labels = JSON.parse(data.labels);
      graph_chart.update();

      // temp widget
      var data = temp_dom.dataset;
      if (data == undefined || data == null){
        data = {
          cur: 0.0,
          max: 150,
        };
      }

      if (data.cur == null){
        data.cur = 0.0;
      }
      if (data.max == null){
        data.max = 0.0;
      }
      if (data.fmtCur == null || data.fmtCur == "null"){
        data.fmtCur = "0.0 °F";
      }
      if (data.fmtAvg == null){
        data.fmtAvg = "0.0 °F";
      }

/*      if (widget_chart.data.datasets[0] != undefined && widget_chart.data.datasets[0].data != undefined) {
        widget_chart.data.datasets[0].data[0] = data.cur;
        widget_chart.data.datasets[0].data[1] = data.max - data.cur;
      }

      widget_chart.options.elements.center.text = data.fmtCur;
      widget_chart.update();
      */
      var avg_dom = $('#temp-'+id+'-avg-'+i)[0];
      if (avg_dom != undefined){
        avg_dom.innerHTML = data.fmtAvg;
      }
      graph_timeout(id, graph_chart, i);
    }, 5000);
};

pressure_timeout = function(id, graph_chart, i){
  setTimeout(function() {
    var graph_dom = $('#pressure-graph-'+id+'-data-'+i)[0];
    var pressure = $('#pressure-'+id+'-cur-'+i)[0];

    if (graph_dom == undefined || pressure == undefined){
      return
    }
    // graph widget
    var data = graph_dom.dataset;
    graph_chart.data.datasets[0].data = JSON.parse(data.points);
    graph_chart.labels = JSON.parse(data.labels);
    graph_chart.update();

    // pressure widget
    var data = pressure.dataset;
    if (data == undefined || data == null){
      data = {
        cur: 0.0,
        max: 150,
      };
    }

    if (data.cur == null){
      data.cur = 0.0;
    }
    if (data.max == null){
      data.max = 0.0;
    }
    if (data.fmtCur == null || data.fmtCur == "null"){
      data.fmtCur = "0.0 mpsi";
    }
    if (data.fmtAvg == null){
      data.fmtAvg = "0.0 mpsi";
    }
/*
    if (widget_chart.data.datasets[0] != undefined && widget_chart.data.datasets[0].data != undefined) {
      widget_chart.data.datasets[0].data[0] = data.cur;
      widget_chart.data.datasets[0].data[1] = data.max - data.cur;
    }

    widget_chart.options.elements.center.text = data.fmtCur;
    widget_chart.update();*/
    var avg_dom = $('#pressure-'+id+'-avg-'+i)[0];
    if (avg_dom != undefined){
      avg_dom.innerHTML = data.fmtAvg;
    }
    pressure_timeout(id, graph_chart, i);
  }, 5000);
};

formatLabel = function(val, mode){
  if (val == null || val == 0.0){
    val = 0;
  }
  if (mode == "temp"){
    val = val +" °F";
  }else if (mode == "pressure"){
    val = val + " mpsi";
  }else{
    val = val
  }
  return val;
};
formatItem = function(item){
  val = item.formattedValue;
  axis = item.dataset.xAxisID;
  if (val == 0.0){
    val = 0;
  }
  if (axis == "x_temp"){
    val = val +" °F";
  }
  if (axis == "x_pressure"){
    val = val + " mpsi";
  }
  if (axis == "x_runtime"){
    val = val + " hours";
  }
  return val;
};
